import type React from "react";
import type { SalesposterView } from "../../../client";
import type { Article, Resource } from "../../../client/ContentApi";
import type { ResourceGroup } from "../../../client/ContentApi/models/resourceGroup";

import { Salesposter } from "../../Salesposter/";
import { AnswerResource } from "./AnswerResource";
import { BylineResource } from "./BylineResource";
import { CarouselResource } from "./CarouselResource";
import { ChevronDescriptionResource } from "./ChevronDescriptionResource";
import { EvergreenArticleLinkResource } from "./EvergreenArticleLinkResource";
import { ExternalImageResource } from "./ExternalImageResource";
import { FactResource } from "./FactResource";
import { GalleryResource } from "./GalleryResource";
import { HorizontalDividerResource } from "./HorizontalDividerResource";
import { ImageResource } from "./ImageResource";
import { InternalArticleResource } from "./InternalArticleResource";
import { LabeledIconResource } from "./LabeledIconResource";
import { ModelMapResource } from "./ModelMapResource";
import { NumberFactResource } from "./NumberFactResource";
import { OriginBylineResource } from "./OriginBylineResource";
import { PremiumInformationBoxResource } from "./PremiumInformationBox";
import { QuestionResource } from "./QuestionResource";
import { QuoteResource } from "./QuoteResource";
import { StoryVignetteResource } from "./StoryVignetteResource";
import { SubheadingResource } from "./SubheadingResource";
import { TextResource } from "./TextResource";
import { TitleResource } from "./TitleResource";
import { UrlResource } from "./UrlResource";
import { WebviewResource } from "./WebviewResource";

export type ResourceComponent<T extends Resource> = React.FC<{
  resource: T;
  article?: Article;
  isOpen?: boolean;
  isExpanded?: boolean;
  decoration?: React.CSSProperties;
  group?: ResourceGroup;
  type?: SalesposterView;
  isMainArticle?: boolean;
}>;

export const resourceToComponentMap = {
  Image: ImageResource,
  Url: UrlResource,
  Title: TitleResource,
  Text: TextResource,
  StoryVignette: StoryVignetteResource,
  Subheading: SubheadingResource,
  Carousel: CarouselResource,
  Map: ModelMapResource,
  Webview: WebviewResource,
  ExternalImage: ExternalImageResource,
  Article: InternalArticleResource,
  Gallery: GalleryResource,
  Question: QuestionResource,
  Answer: AnswerResource,
  PremiumInformationBox: PremiumInformationBoxResource,
  Quote: QuoteResource,
  Fact: FactResource,
  NumberFact: NumberFactResource,
  SalesPosterContainer: Salesposter,
  HorizontalDivider: HorizontalDividerResource,
  LabeledIcon: LabeledIconResource,
  OriginByline: OriginBylineResource,
  ChevronDescription: ChevronDescriptionResource,
  Byline: BylineResource,
  EvergreenArticleLink: EvergreenArticleLinkResource
} as {
  [key: string]: ResourceComponent<any>;
};
