import type { Image } from "../../../../client";

import { imageServer } from "../../../../lib/image";
import { pickWatermarkByFormat } from "../ImageResource";

export const imageSrc = (id: string, size: number) =>
  `${imageServer}/images/${id}?w=${size}`;

export const computeImage = (image: Image) => ({
  fallbackUrl: imageSrc(image.image_asset.id, 750),
  imageUrl: imageSrc(image.image_asset.id, 1360),
  watermark: image.watermarks
    ? pickWatermarkByFormat(
        image.watermarks,
        image.format === "1x1" ? image.format : "2x1"
      )
    : undefined
});
