import React from "react";

import { Arrow } from "./Arrow";
import { LinkTitle } from "./LinkTitle";

interface Props {
  title: string;
  stylePostfix: string;
}

export const OmniMerUrl = (props: Props) => {
  return (
    <>
      <LinkTitle {...props} />
      <Arrow />
    </>
  );
};
