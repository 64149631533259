import React, { useContext } from "react";

import type {
  Block,
  BlockHeading,
  BlockParagraph,
  BlockQuote,
  Inscription,
  Resource,
  Resources,
  Text
} from "../../../../client";
import type { ResourceComponent } from "../resourceToComponents";

import {
  isBlockHeading,
  isBlockListOrdered,
  isBlockListUnordered,
  isBlockParagraph,
  isBlockQuote
} from "../../../../client";
import { ArticleContext } from "../../../../contexts/ArticleContext";
import { getFormatPostfix, getGroupedStyle } from "../../helpers";
import { isFullWidth } from "../helpers/styling";
import { Markup } from "./Markup";

const renderList = (items: Array<Inscription>) =>
  items.map((item, i) => <li key={i}>{item.value}</li>);
const renderOrderedList = (items: Array<Inscription>, i: number) => (
  <ol className="resource--text-list" key={i}>
    {renderList(items)}
  </ol>
);
const renderUnorderedList = (items: Array<Inscription>, i: number) => (
  <ul className="resource--text-list" key={i}>
    {renderList(items)}
  </ul>
);
const renderHeading = (heading: BlockHeading, i: number) => (
  <h2 className="resource resource--subheading" key={i}>
    {heading.text.value}
  </h2>
);
const renderQuote = (quote: BlockQuote, i: number) => (
  <blockquote className="resource--text-quote" key={i}>
    {quote.text.value}
  </blockquote>
);
const renderParagraph = (paragraph: BlockParagraph, i: number) => (
  <p key={i}>
    <Markup text={paragraph.text} />
  </p>
);
const renderLeadParagraph = (paragraph: BlockParagraph, i: number) => (
  <p className="resource--text-lead" key={i}>
    <Markup text={paragraph.text} />
  </p>
);
const renderLead = (paragraph: Block, i: number) => {
  if (isBlockListOrdered(paragraph))
    return renderOrderedList(paragraph.items, i);
  else if (isBlockListUnordered(paragraph))
    return renderUnorderedList(paragraph.items, i);
  else if (isBlockHeading(paragraph)) return renderHeading(paragraph, i);
  else if (isBlockQuote(paragraph)) return renderQuote(paragraph, i);
  else if (isBlockParagraph(paragraph))
    return renderLeadParagraph(paragraph, i);

  return null;
};

const handleParagraph = (paragraph: Block, i: number) => {
  if (isBlockListOrdered(paragraph))
    return renderOrderedList(paragraph.items, i);
  else if (isBlockListUnordered(paragraph))
    return renderUnorderedList(paragraph.items, i);
  else if (isBlockHeading(paragraph)) return renderHeading(paragraph, i);
  else if (isBlockQuote(paragraph)) return renderQuote(paragraph, i);
  else if (isBlockParagraph(paragraph)) return renderParagraph(paragraph, i);
  return null;
};

export const TextResource: ResourceComponent<Text> = ({
  resource,
  decoration,
  group
}) => {
  const { article } = useContext(ArticleContext);
  const formatPostfix = getFormatPostfix(article.format);

  const mainText =
    resource.subtype === "lead"
      ? resource.paragraphs.map(renderLead)
      : resource.paragraphs.map(handleParagraph);

  const hasSalesposterInArticle = article.resources.some(
    r => r.type === "SalesPosterContainer"
  );

  return (
    <div
      role="presentation"
      className={`resource resource--text ${
        isFullWidth(decoration) ? "resource--fullwidth" : ""
      } ${
        hasSalesposterInArticle &&
        // Used to avoid trimming text before ChevronDescription on licensed content behind paywall
        isLastTextResource(resource, article.resources)
          ? "resource--text--clamped"
          : ""
      }
        resource--text${formatPostfix}
        ${getGroupedStyle(group)}
      `}
      style={decoration}
    >
      {mainText}
    </div>
  );
};

const isLastTextResource = (resource: Resource, resources: Resources) => {
  const textResources = resources.filter(r => r.type === "Text");
  return textResources.indexOf(resource) === textResources.length - 1;
};
