import React, { useCallback, useEffect, useRef } from "react";

import { getTwitterWidget } from "../../../../helpers";

const EmbeddedTweet = ({ tweetId }: { tweetId: string }) => {
  const tweetRef = useRef<HTMLDivElement | null>(null);

  const loadTweet = useCallback(async () => {
    try {
      const twitterWidget = await getTwitterWidget();
      if (tweetRef.current && twitterWidget) {
        await twitterWidget?.widgets?.createTweet(tweetId, tweetRef.current);
      }
    } catch (e) {
      console.error(e);
    }
  }, [tweetRef, tweetId]);

  useEffect(() => {
    loadTweet();
  }, [loadTweet, tweetId]);

  return <div ref={tweetRef} />;
};

export const TweetResource = ({ tweetId }: { tweetId: string }) => {
  return <EmbeddedTweet tweetId={tweetId} />;
};
