import React from "react";
import stylizr from "@aftonbladet/stylizr";

import type { Inscription } from "../../../../client";

const strToEm = (text: string, id: string) => <em key={id}>{text}</em>;
const strToStrong = (text: string, id: string) => (
  <strong key={id}>{text}</strong>
);
const strToUnderline = (text: string, id: string) => (
  <span key={id} style={{ textDecoration: "underline" }}>
    {text}
  </span>
);

const strToExternalLink = (
  text: string,
  id: string,
  { uri }: { uri: string }
) => {
  return (
    <a
      key={id}
      className="resource--text--link"
      target="_blank"
      rel="noreferrer"
      href={uri}
    >
      {text}
    </a>
  );
};

const colorStr = (text: string, id: string, { color }: { color: string }) => {
  return (
    <span key={id} style={{ color }}>
      {text}
    </span>
  );
};

const stylizrInstance = stylizr({
  "style:em": strToEm,
  "style:strong": strToStrong,
  "style:underline": strToUnderline,
  "style:color": colorStr,
  "link:external": strToExternalLink,
  br: (_text: string, id: string) => <br key={id} />
});

export const Markup = ({ text: { value, markup } }: { text: Inscription }) => {
  const result = stylizrInstance(value, markup);
  return <>{result}</>;
};
