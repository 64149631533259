import React from "react";

import type { Decoration } from "../../../../client";

import { LinkTitle } from "../common/LinkTitle";
import { getDecorationProperties } from "../../../../client";

export const LinkResource = ({
  label,
  titleValue,
  stylePostfix,
  source,
  omniKeySuffix
}: {
  titleValue: string;
  label?: string;
  stylePostfix?: string;
  source: string;
  omniKeySuffix?: {
    value: string;
    decoration?: Decoration;
  };
}) => {
  return (
    <>
      <span className="resource--link-label">{label ? `${label} ` : ""}</span>
      <LinkTitle title={titleValue} stylePostfix={stylePostfix} />
      <div className="resource--link-source">
        <span>{source}</span>
        {process.env.NEXT_PUBLIC_MER && (
          <span
            className="resource--link-source-suffix"
            style={getDecorationProperties(omniKeySuffix?.decoration)}
          >
            {omniKeySuffix?.value}
          </span>
        )}
      </div>
    </>
  );
};
