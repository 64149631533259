import React, { useContext } from "react";

import type { Title } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getArticleDisplaySize } from "./helpers/article-size";
import { TeaserContext } from "../../../contexts/TeaserContext";
import { isFullWidth } from "./helpers/styling";
import { getFormatPostfix } from "../helpers";

export const TitleResource: ResourceComponent<Title> = ({
  resource,
  decoration,
  isMainArticle = false
}) => {
  const { article } = useContext(ArticleContext);
  const { teaserType } = useContext(TeaserContext);
  const displaySize = getArticleDisplaySize(article);
  const sizeClass = `resource--title--${
    article.breaking || displaySize !== "large" || teaserType === "small"
      ? "small fs--h2"
      : "large fs--h1"
  }`;
  const vignettePremium =
    article.is_premium && "resource--title-vignette--premium";

  const vignette = resource.text.vignette && (
    <span className={`resource--title-vignette ${vignettePremium}`}>
      {resource.text.vignette.value}
      {resource.text.vignette.separator}
    </span>
  );

  const formatPostfix = getFormatPostfix(article.format);

  /* for main article on article pages we want to use h1 because of SEO reasons */
  const HeadingType = `h${
    isMainArticle ? 1 : 2
  }` as keyof React.JSX.IntrinsicElements;

  return (
    <div
      className={`resource--title-container ${
        isFullWidth(decoration) ? "resource--fullwidth" : ""
      }`}
      style={decoration}
    >
      <HeadingType
        role="presentation"
        className={`resource resource--title resource--title${formatPostfix} ${sizeClass}`}
      >
        {vignette}
        {resource.text.value}
      </HeadingType>
    </div>
  );
};
