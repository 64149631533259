import React, { useContext, useRef, useEffect } from "react";

import type { Webview } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { getGroupedStyle } from "../helpers";
import { getArticleDisplaySize } from "./helpers/article-size";

const isChart = (resource: Webview) => resource?.source;

export const WebviewResource: ResourceComponent<Webview> = ({
  resource,
  group
}) => {
  const { article } = useContext(ArticleContext);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const node = iframeRef.current as any;
    if (node) {
      node.style.height = `${resource.height > 360 ? resource.height : 360}px`;
      node.style.width = "100%";
    }
  }, [iframeRef, resource.height]);

  const displaySize = getArticleDisplaySize(article);
  return (
    <div className={`resource resource--webview ${getGroupedStyle(group)}`}>
      <div
        className={`resource resource--webview resource--webview--${displaySize}`}
      >
        <iframe
          title="webview"
          ref={iframeRef}
          sandbox={`allow-same-origin allow-popups ${
            isChart(resource) ? "allow-scripts" : ""
          }`}
          className="resource-webview"
          allow="fullscreen"
          src={resource.url}
          scrolling="no"
        />
      </div>
    </div>
  );
};
