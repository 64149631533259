import React from "react";

interface Props {
  title: string;
  stylePostfix?: string;
  className?: string;
}

export const LinkTitle = ({
  title,
  stylePostfix = "",
  className = ""
}: Props) => {
  return (
    <span
      className={`resource--link-title resource--link-title${stylePostfix} ${className}`}
    >
      {title}
    </span>
  );
};
