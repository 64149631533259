import type { ModelMap } from "../../../../client";

const newsroom = process.env.NEXT_PUBLIC_NEWSROOM;

interface MapDimensions {
  width: number;
  height: number;
}

export function gMapLink({ location, zoom }: ModelMap) {
  return `https://maps.google.com/maps/?q=${location.lat}+${location.lon}&ll=${location.lat}+${location.lon}&z=${zoom}`;
}

export function staticMapSrc(
  { width, height }: MapDimensions,
  { location, zoom }: ModelMap
) {
  const markerColor = newsroom === "omninext" ? "tomato" : "teal";

  return `https://maps.vgc.no/api/maps/basic/static/${location.lon},${location.lat},${zoom}/${width}x${height}.png?markers=${location.lon},${location.lat},${markerColor}`;
}
