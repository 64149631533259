import React from "react";

import ArrowIcon from "../../../../../public/img/svg/url_simple-arrow.svg";

interface Props {
  className?: string;
}

export const Arrow = ({ className = "" }: Props) => {
  return <ArrowIcon className={`resource--link-arrow ${className}`} />;
};
