/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useEffect, useState } from "react";

import type { Url } from "../../../../client";
import type { ResourceComponent } from "../resourceToComponents";

import { ArticleContext } from "../../../../contexts/ArticleContext";
import { PremiumInformationButton } from "../../../Modal/buttons/PremiumInformationButton";
import { getGroupedStyle, getStylePostfix, isSimpleUrl } from "../../helpers";
import { OmniMerUrl } from "../common/OmniMerUrl";
import { isFullWidth } from "../helpers/styling";
import { VideoResource } from "./VideoResource";
import { TweetResource } from "./TweetResource";
import { useCookiePermission } from "../../../../hooks/cookiePermission/useCookiePermission";
import { LinkResource } from "./LinkResource";

const isVideo = (resource: Url) => {
  if (
    resource?.source !== "Youtube" &&
    resource?.source !== "youtu.be" &&
    resource?.source !== "www.youtube.com"
  ) {
    return false;
  }
  if (resource?.data?.source !== "youtube") {
    return false;
  }
  return !!resource?.data?.custom_properties?.video_id;
};

const isSponsoredLink = (resource: Url) => resource?.sponsored;

const getClassName = (resource: Url, decoration?: React.CSSProperties) => {
  const classNames = ["resource", "resource--link"];

  if (isSponsoredLink(resource)) {
    classNames.push("resource--sponsored");
  }

  if (isFullWidth(decoration)) {
    classNames.push("resource--fullwidth", "resource--link--fullwidth");
  }

  return classNames;
};

const isTweet = (url: Url) => url.data?.type === "tweet";

const getTweetId = (url: string) => {
  const splittedUrl = url.split("/");
  return splittedUrl[splittedUrl.length - 1].split("?")[0];
};

export const UrlResource: ResourceComponent<Url> = ({
  resource,
  isOpen,
  decoration,
  group
}) => {
  const { article } = useContext(ArticleContext);
  const [tweetId, setTweetId] = useState("");
  const [simpleUrl, setSimpleUrl] = useState(false);
  const advertisingPermission = useCookiePermission("CMP:advertising");

  const { title, url, label, source, style } = resource;
  const stylePostfix = getStylePostfix(style);

  const titleValue = title ? title.value : "";
  const classNames = getClassName(resource, decoration);

  const omniKeySuffix = resource?.source_suffix;

  useEffect(() => {
    setSimpleUrl(isSimpleUrl(style));
  }, [style, url]);

  useEffect(() => {
    if (isTweet(resource)) {
      setTweetId(getTweetId(url));
    }
  }, [resource, url]);

  if (isVideo(resource)) {
    return <VideoResource isOpen={isOpen} resource={resource} group={group} />;
  }

  const showEmbeddedTweet = advertisingPermission && tweetId;

  return (
    <div
      className={[
        ...classNames,
        `resource--link${stylePostfix}`,
        getGroupedStyle(group)
      ].join(" ")}
      style={decoration}
    >
      <PremiumInformationButton
        url={url}
        title={titleValue}
        article={article}
        resource={resource}
      >
        {simpleUrl && (
          <OmniMerUrl title={titleValue} stylePostfix={stylePostfix} />
        )}

        {showEmbeddedTweet && <TweetResource tweetId={tweetId} />}

        {!simpleUrl && !showEmbeddedTweet && (
          <LinkResource
            label={label}
            source={source}
            titleValue={titleValue}
            stylePostfix={stylePostfix}
            omniKeySuffix={omniKeySuffix}
          />
        )}
      </PremiumInformationButton>
    </div>
  );
};
